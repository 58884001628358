@media only screen and (min-width: 1279.98px) {
    .MobileMenuList {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .MobileMenuList ul:last-child {
        margin-top: auto;
    }
}

@media screen and (max-width: 1279px) {
    .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
        left: -1px;
    }
}


.mouse-add .mapboxgl-canvas-container.mapboxgl-interactive,
.mouse-add .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
    cursor: crosshair !important;
}

.loading {
    background-color: black;
}

/* Override Driver Styles */

.driver-popover {
    padding: 0;
}

.driver-popover-title {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    padding: 10px 24px;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
}

.driver-popover-description {
    padding: 10px 24px;
}

.driver-popover-footer {
    padding: 10px 24px;
    border-top: 1px solid #e0e0e0;
}

.driver-popover-footer button {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 1px 15px;
    border-radius: 2px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(0, 173, 230, 0.5);
    color: #00ade6;
    font-size: 12px;
    text-transform: none;
}

.driver-popover {
    border-radius: 2px;
}

.arrow-container {
    position: relative;
}

.arrow-container::before {
    content: '';
    position: absolute;
    left: -17px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-right: 15px solid #ccc !important;
}

.swiper-button-next,
.swiper-button-prev {
    color: white !important;
}



.swiper-pagination-bullet {
    background-color: lightgray !important;
}

.swiper-pagination-bullet-active {
    background-color: #343a40 !important;
}

.swiper-slide {
    width: fit-content !important;
}