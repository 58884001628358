html {
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Roboto', sans-serif !important;
  font-size: 13px !important;
  min-height: 100%;
}

strong,
b {
  font-weight: bolder;
}

.text-center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
}

.d-none {
  display: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

/** Material input */
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

a {
  text-decoration: inherit;
  /* no underline */
}

#spinner,
#overlay,
#spinner[style],
#spinner[style],
body>#spinner,
body>#overlay {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
  background: transparent !important;
  color: transparent !important;
}

/* Chrome, Safari, Edge, Opera */
.numInput input::-webkit-outer-spin-button,
.numInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


footer {
  z-index: 2000 !important;
}

#scene {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin-top: 50px;
}

@media only screen and (min-width: 1279px) {
  .MobileMenuList {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .MobileMenuList ul:last-child {
    margin-top: auto;
  }
}

 
#canvas {
  width: 100%;
  height: 250px;
}

#canvas {
  cursor: crosshair;
}

#output {
  padding: 5px;
  color: white;
  background-color: #00ade6;
}

#tooltip {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  color: white;
  padding: 3px;
  border-radius: 3px;
  z-index: 2000;
}

.mapbox-gl-draw_ctrl-draw-btn.active, .mapbox-gl-draw_ctrl-draw-btn.active:hover {
  background-color: rgb(194 227 255 / 76%);  
} 